@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400&display=swap");

body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto-slab: "Roboto Slab";

  /* Colors */
  --color-white: #fff;
}
