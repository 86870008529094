.rotate-enter,
.rotate-enter-active,
.rotate-exit,
.rotate-exit-active {
  transform-origin: left;
}

.rotate-enter {
  transform: rotateX(-90deg) scale(0.1);
  opacity: 0;
}

.rotate-enter-active {
  transform: rotateX(0) scale(1);
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.rotate-exit {
  transform: rotateX(0deg) scale(1);
  opacity: 1;
}

.rotate-exit-active {
  transform: rotateX(90deg) scale(0.1);
  opacity: 0;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}
