$customBlue: #2a6bdd;

.app {
  display: flex;
  justify-content: center;
  .container {
    width: 100%;
    height: 100;

    .link {
      text-decoration: none;
      color: inherit;
    }
  }
}
